<template>
  <div style="width: 100%;background-color: white" ref="box1">
    <div class="box">
      <div>
        患者
        <van-popover
            v-model="showPopover"
            theme="dark"
            trigger="click"
            placement="right-start"
            style="width: 30%"
        >
          <div style="width: 160px;padding: 15px">
            用于管理同一患者的所有评分结果，非必选
          </div>
          <template #reference>
            <van-icon name="info" color="#A3A3A3"/>
          </template>
        </van-popover>
<!--        <van-popover-->
<!--            v-model="showPopover"-->
<!--            theme="dark"-->
<!--            trigger="click"-->
<!--            :actions="[{text: '用于管理同一患者的所有评分结果，非必选111'}]"-->
<!--        >-->
<!--          <template #reference>-->
<!--            <span></span>-->
<!--          </template>-->
<!--        </van-popover>-->
      </div>
      <div style="display: flex;    align-items: baseline;" @click="userId?userOpen():''">
        <div v-if="typeof selectValue === 'object' && selectValue.id">
          {{selectValue.name}}
        </div>
        <div style="color: #A6A6A6" v-else>
          {{userId?'请选择':'登录后可选择'}}
        </div>
<!--        <div>-->
          <van-icon name="arrow" style="margin-left: 5px" color="#A7A7A7" size="17px"/>
<!--        </div>-->
      </div>
    </div>
    <van-popup
        ref="vanTan"
        :style="vanStyle"
        v-model="showPicker"
        position="bottom"
        round
    >
      <div style="text-align: center;font-size: 17px;width: 100%;margin-top: 15px">我的患者</div>
      <div style="height: 85%;overflow-y: scroll;margin-top: 10px">
        <div v-if="spinner" style="display: flex;justify-content: center;margin-top: 50px">
          <van-loading />
        </div>
        <div v-else-if="userData.length > 0" class="div-tag" v-for="(item, index) in userData" :key="index">
          <div style="display:flex;width: 50%;justify-content: space-between;    align-items: baseline;">
            <span>
              {{item.name.replace(/^(.).{1}/, '$1*')}}
            </span>
            <span style="color: #D0D0D0;font-size: 15px">
              {{item.sex == '1' ? '男' : item.sex == '2' ? '女' : '未知'}}
            </span>
            <span v-if="item.birthday && item.birthday !== ''" style="color: #D0D0D0;font-size: 15px">
              {{item.birthday}}
            </span>
            <span style="color: white" v-else>
              2024-06-07
            </span>
          </div>
          <div style="display: flex;align-items: center;">
            <div :class="typeof selectValue === 'object' && selectValue.id && selectValue.id === item.id ? 'selectButton' : 'noSelectButton'"  @click="selectClick({id: item.id, name: item.name, sex: item.sex, birthday: item.birthday})">
              选择
            </div>
            <div style="color: #0292FC;font-size: 11px;" @click="editUser(item)">
              编辑
            </div>
          </div>
        </div>
        <div style="color: #A6A6A6;text-align: center;margin-top: 20px" v-else>
          暂无患者数据
        </div>
      </div>
      <div style="color: #0C64E7;text-align: center;padding: 15px" @click="addOpen">
        + 添加患者
      </div>
    </van-popup>
    <medicalForm :prop-width="propWidth" :editData="userEditData" :visible="editStatus" @close="hideStudent" @ok="userOk" :memberId="memberId" :type="type">

    </medicalForm>
  </div>
</template>

<script>
import medicalForm from '@/components/medicalForm.vue'
import {getFormulaPatientApi} from "@/service/api";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
// import {bus} from "@/utils/eventBus";
export default
{
  name: "MyPatient",
  components: {
    medicalForm
  },

  props: {
    memberId:{
      type: [String, Number],
      required: true,
    },
    selectValue:{
      type: [String, Object],
      required: true,
    },
  },
  data () {
    return {
      showPopover: false,
      vanStyle: {
        height: '80%'
      },
      userEditData: {},
      spinner: false,
      userData: [],
      showPicker: false,
      editStatus: false,
      propWidth: null,
      type: 'add',
      userId:"",
    }//dataReturn
  },
  created() {
    this.test()
  },
  methods: {
    userOk () {
      this.editStatus = false
      this.getUserData()
    },
    async test(){
      //像接口头部传用户信息
      this.userId=this.$route.query.memberId
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async userOpen () {
      // const a = bus.$emit('event1')
      // console.log(a)
      const app = document.getElementById('app')
      // const doHeight = document.body.offsetHeight
      // console.log(app)
      this.propWidth = app.offsetWidth * 0.75 + 'px'
      // console.log(app.offsetWidth)
      this.vanStyle = {
        left: app.offsetLeft + 'px',
        width: app.offsetWidth + 'px',
        height: app.offsetHeight * 0.8 + 'px',
      }
      // console.log(this.vanStyle)
      this.spinner = true
      this.showPicker = true
      const res = await getFormulaPatientApi()
      if (res.code === 0) {
        this.userData = res.data
        this.spinner = false
      }
    },
    async getUserData () {
      const res = await getFormulaPatientApi()
      if (res.code === 0) {
        this.userData = res.data
      }
    },
    addOpen () {
      this.type = 'add'
      this.editStatus = true
    },
    selectClick (data) {
      this.$emit('change', data)
      this.showPicker = false
    },
    hideStudent () {
      this.editStatus = false
    },
    editUser (row) {
      // const a = bus.$emit('event1')
      // console.log(a)
      const app = document.getElementById('app')
      // const doHeight = document.body.offsetHeight
      // console.log(app)
      this.propWidth = app.offsetWidth * 0.75 + 'px'
      // console.log(app.offsetWidth)
      this.vanStyle = {
        left: app.offsetLeft + 'px',
        width: app.offsetWidth + 'px',
        height: app.offsetHeight * 0.8 + 'px',
      }
      this.type = 'edit'
      this.userEditData = {...row}
      this.editStatus = true
    }
  },
  // computed: {
  //   copWidth () {
  //     const box1 = document.getElementById('box1')
  //     return box1 ? box1.offsetWidth + 'px' : '100%'
  //   }
  // }
}
</script>

<style scoped>
.div-tag {
  margin: 20px 30px;
  padding-bottom: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #efeded;

}
.selectButton {
  background-color: #0392FB;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  color: white;
  padding: 3px 0;
  font-size: 11px;
  margin-right: 10px;
}
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 45px;
  border-bottom: 1px solid #f0f0f0;
}
.noSelectButton {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  color: #0392FA;
  padding: 3px 0;
  font-size: 11px;
  margin-right: 10px;
  border: 1px solid #0392FA;
}
</style>